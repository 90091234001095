var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.isLoading)?_c('div',[_c('OtherLoading')],1):_c('div',[_c('h4',{staticClass:"mr-sm-4 mb-3 header-tablepage"},[_vm._v("RQP - Request Purchase")]),_c('b-tabs',{attrs:{"content-class":"py-3"},model:{value:(_vm.tabIndex),callback:function ($$v) {_vm.tabIndex=$$v},expression:"tabIndex"}},_vm._l((_vm.statusList),function(status){return _c('b-tab',{key:status.id,attrs:{"value":status.id},on:{"click":function($event){return _vm.onTabChange(status.id, $event)}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(status.name)+" "),_c('b-badge',{attrs:{"pill":""}},[_vm._v(" "+_vm._s(status.count)+" "),_c('span',{staticClass:"sr-only"},[_vm._v("count items list")])])]},proxy:true}],null,true)},[_c('div',{staticClass:"d-flex justify-content-between mb-2"},[_c('InputText',{attrs:{"type":"text","faIcon":"search","className":"mb-2 bg-second w-50","placeholder":"ค้นหาเลขที่เอกสาร"},on:{"onClickIcon":_vm.onSearch,"onEnter":_vm.onSearch},model:{value:(_vm.filter.search),callback:function ($$v) {_vm.$set(_vm.filter, "search", $$v)},expression:"filter.search"}}),_c('b-button',{staticClass:"bg-main bt-height",attrs:{"disabled":_vm.isLoading},on:{"click":function($event){return _vm.goDetail(0)}}},[_c('font-awesome-icon',{staticClass:"mr-0 mr-sm-1",attrs:{"icon":"plus"}}),_vm._v(" สร้าง ")],1)],1),_c('b-table',{attrs:{"striped":"","hover":"","busy":_vm.isBusy,"items":_vm.items,"fields":_vm.fields,"show-empty":"","empty-text":"No matching records found"},scopedSlots:_vm._u([{key:"cell(request_branch_name)",fn:function({ item }){return [_c('div',{staticClass:"text-center text-black my-2"},[_vm._v(" "+_vm._s(item.request_branch_code)+" "+_vm._s(item.created_by_name)+" "),_c('br'),_c('span',{staticClass:"text-gray"},[_vm._v(_vm._s(item.request_branch_name))])])]}},{key:"cell(rqp_status_name)",fn:function({ item }){return [_c('div',{class:{
                'text-success': item.rqp_status_id == 3,
                'text-gray': item.rqp_status_id == 1,
                'text-warning': item.rqp_status_id == 2,
              }},[_vm._v(" "+_vm._s(item.status_display || "-")+" ")])]}},{key:"cell(note)",fn:function({ item }){return [_vm._v(" "+_vm._s(item.note || "-")+" ")]}},{key:"cell(rqp_no)",fn:function({ item }){return [_c('a',{staticClass:"text-underline my-2",attrs:{"href":`/request-purchase/detail/${item.rqp_id}`}},[_vm._v(" "+_vm._s(item.rqp_no)+" ")])]}},{key:"cell(created_time)",fn:function(data){return [_c('div',{staticClass:"text-center text-black my-2"},[_vm._v(" "+_vm._s(_vm.$moment(data.item.created_time).format("DD/MM/YYYY"))+" "),_c('br'),_vm._v(" "+_vm._s(_vm.$moment(data.item.created_time).format("HH:mm:ss"))+" ")])]}},{key:"cell(action)",fn:function(data){return [(data.item.rqp_status_id == 1)?_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_c('b-dropdown',{ref:"dropdown",refInFor:true,attrs:{"no-caret":"","variant":"link","right":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('font-awesome-icon',{staticClass:"text-black pointer",attrs:{"icon":"ellipsis-v","title":"detail"}})]},proxy:true}],null,true)},[(data.item.rqp_status_id == 1)?_c('b-dropdown-item',{on:{"click":function($event){return _vm.$router.push(
                      `/request-purchase/detail/${data.item.rqp_id}`
                    )}}},[_vm._v(" แก้ไข ")]):_vm._e()],1)],1):_vm._e()]}},{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-black my-2"},[_c('b-spinner',{staticClass:"align-middle"}),_c('strong',{staticClass:"ml-2"},[_vm._v("Loading...")])],1)]},proxy:true}],null,true)}),_c('Pagination',{attrs:{"pageOptions":_vm.pageOptions,"filter":_vm.filter,"rows":_vm.rows},on:{"handleChangeTake":_vm.handleChangeTake,"pagination":_vm.pagination}})],1)}),1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }